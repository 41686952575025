<template>
  <transition name="van-slide-up">
    <div class="file-preview-page">
      <div class="preview-page-title">
        <span class="title-text">{{ fileName }}</span>
      </div>
      <div class="preview-page-content">
        <ul class="file-img-list">
          <li
            class="file-img-item"
            v-for="(item, index) in imgList"
            :key="index"
          >
            <img :src="item" alt="" />
          </li>
        </ul>
      </div>
      <div class="footer">
        <van-button
          v-if="isComUser"
          type="primary"
          class="footer-button"
          @click="handleLaunch"
          >发起</van-button
        >
        <van-button v-else type="primary" class="footer-button" @click="goBack"
          >返回</van-button
        >
      </div>
    </div>
  </transition>
</template>

<script>
  import { hasAuth } from '@/utils/hasAuth'

  export default {
    name: 'FilePreview',
    data() {
      return {
        fileName: '',
        imgList: [],
        showSelectUserDialog: false,
      }
    },
    created() {
      const { query } = this.$route
      this.fileName = query.name
      this.imgList = [query.fileUrl]
      this.businessId = query.businessId
    },
    computed: {
      isComUser() {
        return this.$store.getters.isComUser
      },
      isComCredit() {
        return this.$store.getters.isComCredit
      },
    },
    methods: {
      async handleLaunch() {
        // 先判断企业认证情况
        if (this.isComUser && this.isComCredit) {
          if (hasAuth(['templateBusiness_edit'])) {
            this.$router.push({
              name: 'LaunchSelectUser',
              query: {
                businessId: this.businessId,
              },
            })
          } else {
            this.$toast({
              message: '无颁发证书权限',
            })
          }
        } else {
          await this.$dialog({
            title: '提示',
            message: '当前企业还未认证，请先进行企业认证后再进行操作',
            confirmButtonText: '去认证',
            cancelButtonText: '取消',
            showCancelButton: true,
          })
          await this.$router.push({
            name: 'AuthSubmit',
          })
        }
      },

      goBack() {
        window.history.back()
      },
    },
  }
</script>

<style lang="less" scoped>
  .file-preview-page {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #fff;
    left: 0;
    top: 0;
    z-index: 99;
    .preview-page-title {
      width: 100%;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      border-bottom: 1px solid #f5f5f5;
      position: static;
      top: 0;
      box-shadow: 2px 0 3px #cccccc;
      z-index: 10;
      .title-text {
        width: 230px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .title-close-button {
        position: absolute;
        right: 16px;
        color: @BLUE;
      }
    }
    .preview-page-content {
      margin-top: 16px;
      padding-bottom: 80px;
      height: calc(100% - 48px);
      box-sizing: border-box;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      position: relative;
      .page-item {
        width: 100%;
        height: 100%;
        box-shadow: 0 1px 2px 2px #ccc;
        .image {
          width: 100%;
          height: 100%;
        }
      }
      .page-item + .page-item {
        margin-top: 16px;
      }
    }
  }

  .file-img-list {
    width: 100%;
    .file-img-item {
      width: 100%;
      box-shadow: 0 0 2px 1px #ccc;
      img {
        width: 100%;
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 8px 8px calc(8px + env(safe-area-inset-bottom));
    box-shadow: 0px -6px 10px 0px rgba(230, 234, 237, 0.3);
    display: flex;
    align-items: center;
    background: @WHITE;
    box-sizing: border-box;
    .footer-button {
      width: 100%;
      height: 44px;
      font-weight: 500;
      font-size: 16px;
    }
  }
</style>
